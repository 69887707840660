<template>
  <v-container fluid>
    <v-row>
      <v-col col="12" md="12" lg="12" outlined>
        <v-card class="pa-4 rounded-xl">
          <v-card-title class="justify-left d-flex flex-row">
            <p>Posiciones</p>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="12" lg="12">
                  <table-posiciones-company :polla_id="userSession.polla_id"></table-posiciones-company>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>
  
  <script>
import { mapActions, mapGetters } from "vuex";
import TablePosicionesCompany from "@/components/tables/TablePosicionesCompany.vue";
export default {
  name: "company-index",
  components: { TablePosicionesCompany },
  data: () => ({}),
  computed : {
    ...mapGetters(["userSession"]),
  }
};
</script>
  
  <style>
  </style>